/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { navigate } from '@reach/router';
// import { StaticQuery, graphql } from 'gatsby';
import { Row, Col } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { Formik, Form } from 'formik';
import Checkbox from '../checkbox/checkbox';
import { trackAnalytics } from '../../helpers/trackAnalytics';

//style
import { Fonts } from '../../global.css';
import { Advanced } from './advanced-search.css';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';

const AdvancedSearch = ({ data, location, searchCallBack }) => {
  const [collapseMC, setCollapseMC] = useState(false);
  const [collapseST, setCollapseST] = useState(false);
  const [collapsePH, setCollapsePH] = useState(false);
  const [collapseSEX, setCollapseSEX] = useState(false);
  const [collapseAGE, setCollapseAGE] = useState(false);
  const [collapseTY, setCollapseTY] = useState(false);
  const [collapseDoc, setCollapseDoc] = useState(false);
  const [searchParams, setSearchParams] = useState(null);
  const [selected, setSelected] = useState(null);
  const [previousValues, setPreviousValues] = useState(null);

  let selectedFilter = [];

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.manifest &&
      window.manifest.masterData
    ) {
      data.site.siteMetadata.masterData = window.manifest.masterData;
    }

    setSelected(
      Object.assign(
        {},
        ...Object.keys(data.site.siteMetadata.masterData.Data).map((key) => ({
          [key]: [],
        }))
      )
    );

    try {
      setSearchParams(
        new URL(window ? window.location.href : location.href).searchParams
      );
    } catch (e) {
      setSearchParams(new URLSearchParams(''));
    }
  }, []);

  const toggleMC = () => {
    setCollapseMC(!collapseMC);
    trackAnalytics('FilterToggle', {
      FilterName: 'Medical Conditions(s)',
      Toggle: collapseMC ? 'off' : 'on',
      PositionInList: 0,
      CurrentValues: getValues('Conditions', []),
    });
  };

  const toggleST = () => {
    setCollapseST(!collapseST);
    trackAnalytics('FilterToggle', {
      FilterName: 'Status',
      Toggle: collapseST ? 'off' : 'on',
      PositionInList: 1,
      CurrentValues: getValues('Status', []),
    });
  };
  const togglePH = () => {
    setCollapsePH(!collapsePH);
    trackAnalytics('FilterToggle', {
      FilterName: 'Phase',
      Toggle: collapsePH ? 'off' : 'on',
      PositionInList: 2,
      CurrentValues: getValues('Phases', []),
    });
  };
  const toggleSEX = () => {
    setCollapseSEX(!collapseSEX);
    trackAnalytics('FilterToggle', {
      FilterName: 'Gender',
      Toggle: collapseSEX ? 'off' : 'on',
      PositionInList: 4,
      CurrentValues: getValues('Gender', []),
    });
  };
  const toggleAGE = () => {
    setCollapseAGE(!collapseAGE);
    trackAnalytics('FilterToggle', {
      FilterName: 'Age Range',
      Toggle: collapseAGE ? 'off' : 'on',
      PositionInList: 3,
      CurrentValues: getValues('AgeRanges', []),
    });
  };

  const toggleTY = () => {
    setCollapseTY(!collapseTY);
    trackAnalytics('FilterToggle', {
      FilterName: 'Study Types',
      Toggle: collapseTY ? 'off' : 'on',
      PositionInList: 6,
      CurrentValues: getValues('StudyTypes', []),
    });
  };

  const toggleDoc = () => {
    setCollapseDoc(!collapseDoc);
    trackAnalytics('FilterToggle', {
      FilterName: 'Available Documents & Data',
      Toggle: collapseDoc ? 'off' : 'on',
      PositionInList: 5,
      CurrentValues:
        getValues('AttachmentTypes', []) +
        ' ' +
        getValues('PatientLevelData', []) +
        ' ' +
        getValues('HasResults', []),
    });
  };

  const getValues = (paramnName, defaultValue) => {
    if (paramnName === 'PatientLevelData') {
      return (
        (searchParams &&
          searchParams.get(paramnName) &&
          'INDIVIDUAL_PATIENT_DATA') ||
        defaultValue
      );
    }
    if (paramnName === 'HasResults') {
      return (
        (searchParams &&
          searchParams.get(paramnName) &&
          'STUDIES_WITH_RESULTS') ||
        defaultValue
      );
    }
    return (
      (searchParams &&
        searchParams.get(paramnName) &&
        searchParams.get(paramnName).split('~')) ||
      defaultValue
    );
  };

  const addOrUpdateSelected = (arr, newObj, key) => {
    let index = arr[key].findIndex((sel) => {
      sel.InternalValue === newObj.InternalValue;
    });
    if (index === -1) {
      arr[key].push(newObj);
    }
    setSelected(arr);
  };

  const buildSelectedCon = () => {
    if (
      typeof window !== 'undefined' &&
      window.manifest &&
      window.manifest.masterData
    ) {
      data.site.siteMetadata.masterData = window.manifest.masterData;
    }
    let tempSelected = Object.assign(
      {},
      ...Object.keys(data.site.siteMetadata.masterData.Data).map((key) => ({
        [key]: [],
      }))
    );
    setSelected(tempSelected);
    Object.values(selectedFilter).forEach((selectedValue) => {
      let values = [selectedValue];
      if (values.length) {
        values.forEach((internalValue) => {
          Object.keys(data.site.siteMetadata.masterData.Data).forEach((key) => {
            if (Array.isArray(data.site.siteMetadata.masterData.Data[key])) {
              data.site.siteMetadata.masterData.Data[key].forEach((item) => {
                if (item.InternalValue === internalValue) {
                  addOrUpdateSelected(
                    tempSelected,
                    {
                      displayValue: item.DisplayValue,
                      internalValue: item.InternalValue,
                    },
                    key
                  );
                }
                if (item.Children && Array.isArray(item.Children)) {
                  item.Children.forEach((subItem) => {
                    if (subItem.InternalValue === internalValue) {
                      addOrUpdateSelected(
                        tempSelected,
                        {
                          displayValue: subItem.DisplayValue,
                          internalValue: subItem.InternalValue,
                        },
                        key
                      );
                    }
                  });
                }
              });
            }
          });
        });
      }
    });
  };

  const handleSubmit = (values) => {
    setSearchParams(null);
    try {
      setSearchParams(
        new URL(window ? window.location.href : location.href).searchParams
      );
    } catch (e) {
      setSearchParams(new URLSearchParams(''));
    }

    let finalQuery = {};
    searchParams.forEach((value, key) => {
      finalQuery[key] = value;
    });
    Object.keys(values).forEach((key) => {
      finalQuery[key] = values[key];
    });

    const GENDER_ALL = 'GENDER_ALL';
    const genderAllIndex = finalQuery.Gender.indexOf(GENDER_ALL);
    
    // Add GENDER_ALL if it’s not present and there are zero or more than one items
    if (genderAllIndex < 0 && (finalQuery.Gender.length === 0 || finalQuery.Gender.length >= 2)) {
      finalQuery.Gender.push(GENDER_ALL);
    }
    
    // Remove GENDER_ALL if there's only one item (which is GENDER_ALL) or exactly two items including GENDER_ALL
    if (genderAllIndex >= 0 && (finalQuery.Gender.length === 1 || finalQuery.Gender.length === 2)) {
      finalQuery.Gender.splice(genderAllIndex, 1);
    }
    
    selectedFilter = Object.values(finalQuery).reduce(
      (acc, val) => acc.concat(val),
      []
    );
    buildSelectedCon();

    let newSearchQuery = Object.keys(finalQuery)
      .map(
        (k) =>
          encodeURIComponent(k) +
          '=' +
          encodeURIComponent(
            Array.isArray(finalQuery[k])
              ? finalQuery[k].join('~')
              : `${finalQuery[k]}`
          )
      )
      .join('&');
    if (newSearchQuery && `?${newSearchQuery}` !== window.location.search) {
      navigate(`/en/results/?${newSearchQuery}`, { replace: true });
      if (searchCallBack && typeof searchCallBack === 'function') {
        setTimeout(() => {
          searchCallBack();
        });
      }
    }
  };

  let conditions = data.site.siteMetadata.masterData.Data.CONDITION.sort(
    (a, b) => a.SortOrder - b.SortOrder
  );

  let genders = data.site.siteMetadata.masterData.Data.GENDER.sort(
    (a, b) => a.SortOrder - b.SortOrder
  );
  let ages = data.site.siteMetadata.masterData.Data.AGERANGE.sort(
    (a, b) => a.SortOrder - b.SortOrder
  );
  let statuses = data.site.siteMetadata.masterData.Data.RECSTATUS.sort(
    (a, b) => a.SortOrder - b.SortOrder
  );
  let phases = data.site.siteMetadata.masterData.Data.PHASE.sort(
    (a, b) => a.SortOrder - b.SortOrder
  );
  let studyType = data.site.siteMetadata.masterData.Data.STUDY_TYPE.sort(
    (a, b) => a.SortOrder - b.SortOrder
  );
  let availableDocs = data.site.siteMetadata.masterData.Data.ATTACHTYPE
    ? data.site.siteMetadata.masterData.Data.ATTACHTYPE.sort(
        (a, b) => a.SortOrder - b.SortOrder
      )
    : [];

  let idSuffix = Math.floor(Math.random(400) * 10000);

  return (
    <Fonts>
      <Advanced>
        {searchParams && (
          <Formik
            enableReinitialize={false}
            initialValues={{
              Conditions:
                (searchParams &&
                  searchParams.get('Conditions') &&
                  searchParams.get('Conditions').split('~')) ||
                [],
              Phases:
                (searchParams &&
                  searchParams.get('Phases') &&
                  searchParams.get('Phases').split('~')) ||
                [],
              Gender:
                (searchParams &&
                  searchParams.get('Gender') &&
                  searchParams.get('Gender').split('~')) ||
                [],
              HealthyVolunteer:
                (searchParams && searchParams.get('HealthyVolunteer')) || '',
              AgeRanges:
                (searchParams &&
                  searchParams.get('AgeRanges') &&
                  searchParams.get('AgeRanges').split('~')) ||
                [],
              Status:
                (searchParams &&
                  searchParams.get('Status') &&
                  searchParams.get('Status').split('~')) ||
                [],
              StudyResults:
                (searchParams &&
                  searchParams.get('StudyResults') &&
                  searchParams.get('StudyResults').split('~')) ||
                [],
              StudyTypes:
                (searchParams &&
                  searchParams.get('StudyTypes') &&
                  searchParams.get('StudyTypes').split('~')) ||
                [],
              AttachmentTypes:
                (searchParams &&
                  searchParams.get('AttachmentTypes') &&
                  searchParams.get('AttachmentTypes').split('~')) ||
                [],
              PatientLevelData:
                (searchParams &&
                  searchParams.get('PatientLevelData') &&
                  searchParams.get('PatientLevelData').split('~')) ||
                [],
              HasResults:
                (searchParams &&
                  searchParams.get('HasResults') &&
                  searchParams.get('HasResults').split('~')) ||
                [],
            }}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              values.Conditions = selectedFilter;
              handleSubmit(values);
              actions.setSubmitting(false);
            }}
            render={({ values, initialValues }) => {
              if (values && initialValues) {
                if (JSON.stringify(values) !== JSON.stringify(previousValues)) {
                  setPreviousValues(values);
                  handleSubmit(values);
                }
              }
              return (
                <Form>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                      }}
                    >
                      {conditions && conditions.length && (
                        <Col
                          className={'check-box-display' + ' check-box-first'}
                        >
                          <div onClick={toggleMC} className={'title-section'}>
                            <div>
                              {collapseMC === true ? (
                                <FaMinusCircle />
                              ) : (
                                <FaPlusCircle />
                              )}
                            </div>
                            <h3>{data.advancedJson.medicalCond}</h3>
                          </div>
                          <div
                            className={'filter-items'}
                            hidden={
                              collapseMC === true ||
                              selected.CONDITION.length < 1
                            }
                          >
                            {selected.CONDITION.map((cond, index) => {
                              return (
                                <Checkbox
                                  key={index}
                                  value={cond.internalValue}
                                  id={cond.internalValue + idSuffix}
                                  name="Conditions"
                                  displayvalue={cond.displayValue}
                                />
                              );
                            })}
                          </div>
                          <Collapse
                            className={'cond-style'}
                            isOpen={collapseMC}
                          >
                            <p
                              className={
                                selected.CONDITION.length < 1
                                  ? 'active'
                                  : 'inactive'
                              }
                            >
                              {'All Medical Conditions'}
                            </p>
                            {conditions.map((condition, index) => (
                              <div
                                key={index}
                                className={'check-box-align' + ' cond-div'}
                              >
                                <div
                                  className={'cond-parent'}
                                  style={{ width: '100%' }}
                                >
                                  <Checkbox
                                    special={true}
                                    value={condition.InternalValue}
                                    name="Conditions"
                                    displayvalue={condition.DisplayValue}
                                    id={condition.InternalValue + idSuffix}
                                  />
                                </div>

                                {condition.Children &&
                                  condition.Children.length && (
                                    <div
                                      className={'check-box-display-children'}
                                    >
                                      {condition.Children.map(
                                        (subCondition, subIndex) => (
                                          <div
                                            className={'check-box-align'}
                                            key={subIndex}
                                          >
                                            <Checkbox
                                              special={true}
                                              value={subCondition.InternalValue}
                                              id={
                                                subCondition.InternalValue +
                                                idSuffix
                                              }
                                              name="Conditions"
                                              displayvalue={
                                                subCondition.DisplayValue
                                              }
                                            />
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                              </div>
                            ))}
                          </Collapse>
                        </Col>
                      )}
                      {statuses && statuses.length && (
                        <Col className={'check-box-display'}>
                          <div onClick={toggleST} className={'title-section'}>
                            <div>
                              {collapseST === true ? (
                                <FaMinusCircle className={'arrow-position'} />
                              ) : (
                                <FaPlusCircle className={'arrow-position'} />
                              )}
                            </div>
                            <h3>{data.advancedJson.status}</h3>
                          </div>
                          <div
                            className={'filter-items'}
                            hidden={
                              collapseST === true ||
                              selected.RECSTATUS.length < 1
                            }
                          >
                            {selected.RECSTATUS.map((cond, index) => {
                              return (
                                <Checkbox
                                  key={index}
                                  value={cond.internalValue}
                                  id={cond.internalValue + idSuffix}
                                  name="Status"
                                  displayvalue={cond.displayValue}
                                />
                              );
                            })}
                          </div>
                          <Collapse isOpen={collapseST}>
                            {statuses.map((status, index) => (
                              <Col key={index} className={'check-box-align'}>
                                <Checkbox
                                  special={true}
                                  value={status.InternalValue}
                                  id={status.InternalValue + idSuffix}
                                  name="Status"
                                  displayvalue={status.DisplayValue}
                                />
                              </Col>
                            ))}
                          </Collapse>
                        </Col>
                      )}
                      {phases && phases.length && (
                        <Col className={'check-box-display'}>
                          <div onClick={togglePH} className={'title-section'}>
                            <div>
                              {collapsePH === true ? (
                                <FaMinusCircle className={'arrow-position'} />
                              ) : (
                                <FaPlusCircle className={'arrow-position'} />
                              )}
                            </div>
                            <h3>{data.advancedJson.phase}</h3>
                          </div>
                          <div
                            className={'filter-items'}
                            hidden={
                              collapsePH === true || selected.PHASE.length < 1
                            }
                          >
                            {selected.PHASE.map((cond, index) => {
                              return (
                                <Checkbox
                                  key={index}
                                  value={cond.internalValue}
                                  id={cond.internalValue + idSuffix}
                                  name="Phases"
                                  displayvalue={cond.displayValue}
                                />
                              );
                            })}
                          </div>
                          <Collapse isOpen={collapsePH}>
                            {phases.map((phase, index) => (
                              <Col key={index} className={'check-box-align'}>
                                <Checkbox
                                  special={true}
                                  value={phase.InternalValue}
                                  id={phase.InternalValue + idSuffix}
                                  name="Phases"
                                  displayvalue={phase.DisplayValue}
                                />
                              </Col>
                            ))}
                          </Collapse>
                        </Col>
                      )}
                      {ages && ages.length && (
                        <Col className={'check-box-display'}>
                          <div onClick={toggleAGE} className={'title-section'}>
                            <div>
                              {collapseAGE === true ? (
                                <FaMinusCircle className={'arrow-position'} />
                              ) : (
                                <FaPlusCircle className={'arrow-position'} />
                              )}
                            </div>
                            <h3>{data.advancedJson.age}</h3>
                          </div>
                          <div
                            className={'filter-items'}
                            hidden={
                              collapseAGE === true ||
                              selected.AGERANGE.length < 1
                            }
                          >
                            {selected.AGERANGE.map((cond, index) => {
                              return (
                                <Checkbox
                                  key={index}
                                  value={cond.internalValue}
                                  id={cond.internalValue + idSuffix}
                                  name="AgeRanges"
                                  displayvalue={cond.displayValue}
                                />
                              );
                            })}
                          </div>
                          <Collapse isOpen={collapseAGE}>
                            {ages.map((age, index) => (
                              <Col key={index} className={'check-box-align'}>
                                <Checkbox
                                  special={true}
                                  name="AgeRanges"
                                  value={age.InternalValue}
                                  id={age.InternalValue + idSuffix}
                                  displayvalue={age.DisplayValue}
                                />
                              </Col>
                            ))}
                          </Collapse>
                        </Col>
                      )}
                      {genders && genders.length && (
                        <Col className={'check-box-display'}>
                          <div onClick={toggleSEX} className={'title-section'}>
                            <div>
                              {collapseSEX === true ? (
                                <FaMinusCircle className={'arrow-position'} />
                              ) : (
                                <FaPlusCircle className={'arrow-position'} />
                              )}
                            </div>
                            <h3>{data.advancedJson.sex}</h3>
                          </div>
                          <div
                            className={'filter-items'}
                            hidden={
                              collapseSEX === true || selected.GENDER.length < 1
                            }
                          >
                            {selected.GENDER.map((cond, index) => {
                              return cond.internalValue.indexOf('ALL') >
                                -1 ? null : (
                                <Checkbox
                                  key={index}
                                  value={cond.internalValue}
                                  id={cond.internalValue + idSuffix}
                                  name="Gender"
                                  displayvalue={cond.displayValue}
                                />
                              );
                            })}
                          </div>
                          <Collapse isOpen={collapseSEX}>
                            {genders.map((gender, index) =>
                              gender.InternalValue.indexOf('ALL') >
                              -1 ? null : (
                                <Col key={index} className={'check-box-align'}>
                                  <Checkbox
                                    special={true}
                                    name="Gender"
                                    value={gender.InternalValue}
                                    id={gender.InternalValue + idSuffix}
                                    displayvalue={gender.DisplayValue}
                                  />
                                </Col>
                              )
                            )}
                          </Collapse>
                        </Col>
                      )}
                      {/* {studyResults && studyResults.length ? (
                          <Col className={'check-box-display'}>
                            <div
                              onClick={this.toggleRES}
                              className={'title-section'}
                            >
                              {collapseRES === true ? (
                                <FaMinusCircle className={'arrow-position'} />
                              ) : (
                                <FaPlusCircle className={'arrow-position'} />
                              )}
                              <h3>{data.advancedJson.resAvailable}</h3>
                            </div>
                            <div
                              className={'filter-items'}
                              hidden={
                                collapseRES === true ||
                                this.selected.STUDYRESULTS.length < 1
                              }
                            >
                              {this.selected.STUDYRESULTS.map((cond, index) => {
                                return (
                                  <Checkbox
                                    key={index}
                                    value={cond.internalValue}
                                    id={cond.internalValue + idSuffix}
                                    name="StudyResults"
                                    displayvalue={cond.displayValue}
                                  />
                                );
                              })}
                            </div>
                            <Collapse isOpen={collapseRES}>
                              {studyResults.map((study, index) => (
                                <Col key={index} className={'check-box-align'}>
                                  <Checkbox
                                    special={true}
                                    name="StudyResults"
                                    value={study.InternalValue}
                                    id={study.InternalValue + idSuffix}
                                    displayvalue={study.DisplayValue}
                                  />
                                </Col>
                              ))}
                            </Collapse>
                          </Col>
                        ) : (
                          undefined
                        )} */}
                      {availableDocs && availableDocs.length ? (
                        <Col className={'check-box-display'}>
                          <div onClick={toggleDoc} className={'title-section'}>
                            <div>
                              {collapseDoc === true ? (
                                <FaMinusCircle className={'arrow-position'} />
                              ) : (
                                <FaPlusCircle className={'arrow-position'} />
                              )}
                            </div>
                            <h3>{data.advancedJson.availableDoc}</h3>
                          </div>
                          <div
                            className={'filter-items'}
                            hidden={
                              collapseDoc === true ||
                              (selected.ATTACHTYPE.length < 1 &&
                                values.PatientLevelData.length < 1 &&
                                values.HasResults.length < 1)
                            }
                          >
                            {selected.ATTACHTYPE.map((cond, index) => {
                              return (
                                <Checkbox
                                  key={index}
                                  value={cond.internalValue}
                                  id={cond.internalValue + idSuffix}
                                  name="AttachmentTypes"
                                  displayvalue={cond.displayValue}
                                />
                              );
                            })}
                            {values.PatientLevelData.length > 0 &&
                              values.PatientLevelData[0] === 'true' && (
                                <Checkbox
                                  value={'true'}
                                  id={'INDIVIDUAL_PATIENT_DATA'}
                                  name={'PatientLevelData'}
                                  displayvalue={'Individual Patient Data'}
                                />
                              )}
                            {values.HasResults.length > 0 &&
                              values.HasResults[0] === 'true' && (
                                <Checkbox
                                  value={'true'}
                                  id={'STUDIES_WITH_RESULTS'}
                                  name="HasResults"
                                  displayvalue={'Studies with Results'}
                                />
                              )}
                          </div>
                          <Collapse isOpen={collapseDoc}>
                            {availableDocs.map((availableDoc, index) => (
                              <Col
                                key={index}
                                className={'check-box-align' + ' doc-section'}
                              >
                                <Checkbox
                                  special={true}
                                  value={availableDoc.InternalValue}
                                  id={availableDoc.InternalValue + idSuffix}
                                  name="AttachmentTypes"
                                  displayvalue={availableDoc.DisplayValue}
                                />
                              </Col>
                            ))}
                            <Col className={'check-box-align' + ' doc-section'}>
                              <Checkbox
                                special={true}
                                id={'INDIVIDUAL_PATIENT_DATA' + idSuffix}
                                value={'true'}
                                displayvalue={'Individual Patient Data'}
                                name={'PatientLevelData'}
                              />
                              <Checkbox
                                special={true}
                                id={'STUDIES_WITH_RESULTS' + idSuffix}
                                value={'true'}
                                displayvalue={'Studies with Results'}
                                name={'HasResults'}
                              />
                            </Col>
                          </Collapse>
                        </Col>
                      ) : null}
                      {studyType && studyType.length && (
                        <Col className={'check-box-display'}>
                          <div onClick={toggleTY} className={'title-section'}>
                            <div>
                              {collapseTY === true ? (
                                <FaMinusCircle className={'arrow-position'} />
                              ) : (
                                <FaPlusCircle className={'arrow-position'} />
                              )}
                            </div>
                            <h3>{data.advancedJson.studyTipes}</h3>
                          </div>
                          <div
                            className={'filter-items'}
                            hidden={
                              collapseTY || selected.STUDY_TYPE.length < 1
                            }
                          >
                            {selected.STUDY_TYPE.map((cond, index) => {
                              return (
                                <Checkbox
                                  key={index}
                                  value={cond.internalValue}
                                  id={cond.internalValue + idSuffix}
                                  name="StudyTypes"
                                  displayvalue={cond.internalValue === 'STUDY_TYPE_OBSERVATIONAL' ? 'Non-Interventional' : cond.displayValue}
                                />
                              );
                            })}
                          </div>
                          <Collapse isOpen={collapseTY}>
                            {studyType.map((study, index) => {
                              if (study.InternalValue === 'STUDY_TYPE_EXPANDEDACCESS') return;
                              let displayValue = (study.InternalValue === 'STUDY_TYPE_OBSERVATIONAL') ? 'Non-Interventional' : study.DisplayValue;
                              return (
                              <Col className={'check-box-align'} key={index}>
                                <Checkbox
                                  special={true}
                                  name="StudyTypes"
                                  value={study.InternalValue}
                                  id={study.InternalValue + idSuffix}
                                  displayvalue={displayValue}
                                />
                              </Col>
                            )})}
                          </Collapse>
                        </Col>
                      )}
                      <button
                        className={'reset-button'}
                        type="button"
                        onClick={() => {
                          navigate(
                            '/en/results/?AgeRanges=&Phases=&AttachmentTypes=&SearchTerm=&Gender=&Status=&document=&Conditions=&MileRadius=100&country=&zip=&HealthyVolunteer=&StudyResults=&StudyTypes=&PatientLevelData=&HasResults='
                          ) &&
                            window &&
                            window.location.reload();
                        }}
                      >
                        {data.advancedJson.reset}
                      </button>
                    </div>
                  </Row>
                </Form>
              );
            }}
          />
        )}
      </Advanced>
    </Fonts>
  );
};

AdvancedSearch.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
  searchCallBack: PropTypes.func,
};

const AdvancedSearchQuery = (props) => (
  <StaticQuery
    query={graphql`
      query AdvancedSearch {
        advancedJson {
          reset
          medicalCond
          status
          phase
          sex
          age
          resAvailable
          healtyVol
          studyTipes
          availableDoc
        }
        site {
          siteMetadata {
            masterData {
              Data {
                RECSTATUS {
                  InternalValue
                  DisplayValue
                  SortOrder
                }
                CONDITION {
                  InternalValue
                  DisplayValue
                  SortOrder
                  Children {
                    InternalValue
                    DisplayValue
                    SortOrder
                  }
                }
                COUNTRY {
                  InternalValue
                  DisplayValue
                  SortOrder
                }
                HEALTHY_VOL {
                  InternalValue
                  DisplayValue
                  SortOrder
                }
                AGERANGE {
                  InternalValue
                  DisplayValue
                  SortOrder
                }
                GENDER {
                  InternalValue
                  DisplayValue
                  SortOrder
                }
                PHASE {
                  InternalValue
                  DisplayValue
                  SortOrder
                }
                STUDY_TYPE {
                  InternalValue
                  DisplayValue
                  SortOrder
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <AdvancedSearch data={data} {...props} />}
  />
);

AdvancedSearchQuery.propTypes = {
  children: PropTypes.node,
};

export default AdvancedSearchQuery;
